"use client";

import { RepeatIcon, SendIcon, ThumbsDownIcon, ThumbsUpIcon } from "lucide-react";
import { type ChangeEvent, useState } from "react";
import { Button } from "~/components/ui/button";
import { Textarea } from "~/components/ui/textarea";
import { type ReviewType, useFeedback } from "./useFeedback";
export const FeedbackForm = () => {
  const [feedbackReview, setFeedbackReview] = useState<ReviewType>();
  const [feedbackComment, setFeedbackComment] = useState("");
  const [feedbackId, setFeedbackId] = useState<string | undefined>();
  const [feedbackSent, setFeedbackSent] = useState<boolean>(false);
  const [feedbackSentLoading, setFeedbackSentLoading] = useState<boolean>(false);
  const {
    createFeedback,
    updateFeedback
  } = useFeedback();
  const handleFeedbackTextChange = async (e: ChangeEvent<HTMLTextAreaElement>) => {
    setFeedbackComment(e.target.value);
  };
  const handleFeedbackReview = (review: ReviewType) => async () => {
    setFeedbackReview(review);
    try {
      const result = await createFeedback({
        review,
        page: window.location.href
      });
      setFeedbackId(result?.id);
    } catch {
      // TODO: log error
      // We don't show the user any errors here
    }
  };
  const handleFeedbackSubmit = async () => {
    setFeedbackSentLoading(true);
    try {
      if (feedbackId) {
        await updateFeedback({
          id: feedbackId,
          comment: feedbackComment
        });
        return;
      }
      await createFeedback({
        review: feedbackReview!,
        page: window.location.href,
        comment: feedbackComment
      });
    } catch (error) {
      // TODO: log error
      // We don't show the user any errors here
    } finally {
      setFeedbackSent(true);
      setFeedbackSentLoading(false);
    }
  };
  const handleNewFeedback = () => {
    setFeedbackReview(undefined);
    setFeedbackId(undefined);
    setFeedbackComment("");
    setFeedbackSent(false);
  };
  if (feedbackSent) {
    return <div className="mt-8 w-full max-w-[750px]">
        <h3 className="mb-4 text-xl font-semibold">
          Danke für die Rückmeldung!
        </h3>
        <Button onClick={handleNewFeedback} variant="outline" size="default" className="rounded-full border-[0.5px]">
          Neue Rückmeldung
          <RepeatIcon className="ml-2 h-4 w-4" />
        </Button>
      </div>;
  }
  return <div className="mt-8 w-full max-w-[750px]" data-sentry-component="FeedbackForm" data-sentry-source-file="feedback-form.tsx">
      <h3 className="mb-4 text-xl font-semibold">War diese Seite nützlich?</h3>
      {feedbackReview ? <div>
          <Textarea value={feedbackComment} placeholder="Teilen sie uns mit was wir verbessern können" onChange={handleFeedbackTextChange} id="feedbackComment" name="feedbackComment" />
          <Button onClick={handleFeedbackSubmit} variant="outline" size="default" className="mt-4 rounded-full border-[0.5px]" disabled={feedbackComment.length <= 0 || feedbackSentLoading}>
            Senden
            <SendIcon className="ml-2 h-4 w-4" />
          </Button>
        </div> : <div className="flex gap-4">
          <Button onClick={handleFeedbackReview("POSITIVE")} variant="outline" size="default" className="rounded-full border-[0.5px]">
            Ja
            <ThumbsUpIcon className="ml-2 h-4 w-4" />
          </Button>
          <Button onClick={handleFeedbackReview("NEGATIVE")} variant="outline" size="default" className="rounded-full border-[0.5px]">
            Könnte besser sein
            <ThumbsDownIcon className="ml-2 h-4 w-4" />
          </Button>
        </div>}
    </div>;
};